<template lang="pug">
  .add-car-class-form
    .car-class-selection
      p.form-title {{ $t("plan_management.modal.car_class") }}
      AppDropdown(
        allow-empty
        close-on-select
        :value="selectedCarClass"
        :items="carClasses"
        @select="selectCarClass"
        @remove="selectCarClass({})"
      )
    .base-rate-period-selection(v-if="isBaseRatePeriodSelectionShown")
      p.form-title {{ $t("plan_management.modal.base_rate_period_group") }}
      AppDropdown(
        checkbox
        batch-select
        multiple
        allow-empty
        :value="selectedBaseRatePeriodGroups"
        :items="baseRatePeriodSettings"
        @select="selectBaseRatePeriodGroups"
      )
    .time-slot-selection(v-if="isTimeSlotSelectionShown")
      template(v-for="{ id, name } in selectedBaseRatePeriodGroups")
        p.form-title {{ `${$t("plan_management.modal.time_slot")}: ${name}` }}
        AppDropdown(
          allow-empty
          close-on-select
          :value="selectedTimeSlots[id]"
          :items="timeSLots"
          @select="selectTimeSlot(id, $event)"
        )
      .add-button
        AppButton(
          :disabled="isAddNewDisabled"
          title="plan_management.actions.add_new",
          @click="handleAddNew"
        )
</template>

<script>
  // misc
  import { isEmpty, some } from "lodash-es"

  import carClassesModule from "@/config/store/matching/car_class"
  import baseRatePeriodSettingsModule from "@/config/store/plan_management/base_rate_period_settings"

  import withStoreModule from "@/mixins/withStoreModule"

  import { TIME_SLOT_LIST } from "./../../constants.js"

  const baseRatePeriodSettingsMixin = withStoreModule(baseRatePeriodSettingsModule, {
    name: "baseRatePeriodSettings",
    readers: { baseRatePeriodSettings: "items" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "carClasses",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    mixins: [baseRatePeriodSettingsMixin, carClassesMixin],

    data() {
      return {
        timeSLots: TIME_SLOT_LIST,
        selectedCarClass: null,
        selectedBaseRatePeriodGroups: [],
        selectedTimeSlots: {}
      }
    },

    mounted() {
      this.fetchCarClasses({ pagination: { _disabled: true } })
    },

    props: {
      plan: {
        type: Object,
        required: true
      }
    },

    computed: {
      isBaseRatePeriodSelectionShown() {
        return !isEmpty(this.selectedCarClass)
      },

      isTimeSlotSelectionShown() {
        return this.isBaseRatePeriodSelectionShown && !isEmpty(this.selectedBaseRatePeriodGroups)
      },

      isAddNewDisabled() {
        return some(this.selectedBaseRatePeriodGroups, ({ id }) => isEmpty(this.selectedTimeSlots[id]))
      }
    },

    methods: {
      selectCarClass(carClass) {
        this.selectedCarClass = carClass
        this.selectedTimeSlots = {}
      },

      selectBaseRatePeriodGroups(baseRatePeriodGroups) {
        this.selectedBaseRatePeriodGroups = baseRatePeriodGroups
        this.selectedTimeSlots = {}
      },

      selectTimeSlot(baseRatePeriodGroupId, timeSlot) {
        this.$set(this.selectedTimeSlots, baseRatePeriodGroupId, timeSlot)
      },

      buildPrices(timeSLot) {
        return timeSLot.slots.map(slot => ({ timeSlot: { type: timeSLot.id, value: slot }, value: null }))
      },

      buildGroups() {
        return this.selectedBaseRatePeriodGroups.map(group => ({
          base_rate_period_group_name: group.name,
          prices: this.buildPrices(this.selectedTimeSlots[group.id]),
          _addedAt: Date.now()
        }))
      },

      handleAddNew() {
        this.plan.car_classes.push({
          name: this.selectedCarClass.name,
          price_items: this.buildGroups(),
          _addedAt: Date.now()
        })
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .add-car-class-form
    margin: 0 auto
    width: 300px

    .add-button
      margin-top: 10px
      display: flex
      justify-content: center

      button
        background-color: $default-purple

        ::v-deep
          svg
            path
              fill: $default-white

          .app-button-content
            .title
              color: $default-white

    .form-title
      color: $filter-title-color
      font-size: 0.8rem
      margin-left: 8px
      margin-bottom: 0px
      margin-top: 10px

      &:first-child
        margin-top: 0px
</style>
